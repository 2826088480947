import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';
import { SEO, Layout } from '../components';
import { Parallax } from '../components/parallax';

export default function MothersDayPage() {
  return (
    <>
      <SEO title="Mothers Day" />
      <Layout>
        <Hero />
        <Sale />
        <Offers />
      </Layout>
    </>
  );
}

function useGraphQL() {
  const data = useStaticQuery(graphql`
    {
      mothersDayImage: file(relativePath: { eq: "mothers-day-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1920)
        }
      }
      candleImage: file(relativePath: { eq: "centred-earth-candle.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1920)
        }
      }
    }
  `);
  return data;
}

function Hero() {
  const { mothersDayImage } = useGraphQL();
  return (
    <article className="relative flex overflow-hidden md:aspect-ratio-16/9">
      <div className="absolute inset-0 flex items-center flex-1">
        <Parallax className="flex w-full h-full">
          <div className="flex-1 md:-my-12">
            <GatsbyImage
              image={mothersDayImage.childImageSharp.gatsbyImageData}
              className="h-full"
              alt=""
            />
          </div>
        </Parallax>
      </div>
      <div className="relative flex flex-col px-4 py-8 font-sans text-gray-700 bg-white bg-opacity-50 sm:px-6 lg:px-8 md:absolute md:inset-0 md:bg-opacity-30">
        <div className="w-full my-auto md:w-1/2">
          <div className="max-w-lg mx-auto text-gray-700">
            <h1 className="w-full space-y-4 font-serif text-4xl font-semibold leading-none uppercase">
              <span className="block">
                Is Mum ready to relax and feel renewed?
              </span>
              <span className="block">Place your order today!</span>
            </h1>
            <div className="mt-2">
              <p>
                <small className="text-sm">
                  Offer on now. Conditions apply. Expires 09/05/2021.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

function Sale() {
  const { candleImage } = useGraphQL();
  return (
    <article className="flex flex-col px-4 mx-auto mt-16 max-w-7xl sm:mt-24 sm:px-6 lg:mt-32">
      <div className="items-center lg:grid lg:grid-cols-12 lg:gap-8">
        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
          <h2>
            <span className="block mt-1 font-serif text-4xl font-extrabold tracking-tight uppercase">
              <span className="block">Receive a Free</span>
              <span className="block">Centred Earth Candle</span>
            </span>
          </h2>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
            When you purchase a gift voucher for Mothers Day.
          </p>
          <p>
            <Link
              to="/gift-voucher/"
              className="inline-block px-2 py-1 leading-none uppercase transition-all border border-gray-500 hover:bg-gray-800 hover:text-white hover:border-gray-800"
            >
              Buy Now
            </Link>
          </p>
        </div>
        <div className="relative mt-12 sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
          <div className="relative w-full mx-auto lg:max-w-md">
            <GatsbyImage
              image={candleImage.childImageSharp.gatsbyImageData}
              className="relative block w-full h-full drop-shadow"
              alt=""
            />
          </div>
        </div>
      </div>
    </article>
  );
}

function Offers() {
  return (
    <div>
      <div className="px-4 pt-12 sm:px-6 lg:px-8 lg:pt-20">
        <div className="text-center">
          <h2 className="font-serif text-3xl font-semibold uppercase sm:text-4xl">
            Gift Voucher Sale On Now!
          </h2>
          <p className="mx-auto mt-3 text-xl sm:mt-5 max-w-prose">
            Self-care is as important as caring for the family, so we have
            created three special Mother's Day offers which are available for
            you to purchase as a Gift or enjoy yourself!
          </p>
        </div>
      </div>
      <div className="pb-12 mt-16 lg:mt-20 lg:pb-20">
        <div className="relative z-0">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="relative lg:grid lg:grid-cols-7">
              <div className="max-w-md mx-auto lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                <div className="flex flex-col overflow-hidden shadow-lg">
                  <div className="flex flex-col">
                    <div className="px-6 py-10 text-white bg-pink">
                      <div>
                        <h3 className="text-2xl font-medium text-center">
                          Free Travel Candle
                        </h3>
                        <div className="flex items-center justify-center mt-4 bg-pink">
                          <span className="flex items-start px-3 text-6xl tracking-tight">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              $
                            </span>
                            <span className="font-extrabold">24.99</span>
                          </span>
                          <span className="text-xl font-medium">RRP</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col justify-between p-6 border-t-2 border-gray-100 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                      <div className="flex items-start">
                        <p className="text-base font-medium text-gray-500">
                          When you purchase a $100 Gift Voucher
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="max-w-lg mx-auto mt-10 lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                <div className="relative z-10 shadow-xl">
                  <div className="absolute inset-x-0 top-0 transform translate-y-px">
                    <div className="flex justify-center transform -translate-y-1/2">
                      <span className="inline-flex px-4 py-1 text-sm font-semibold tracking-wider text-white uppercase bg-pink">
                        Best Value
                      </span>
                    </div>
                  </div>
                  <div className="px-6 pt-12 pb-10 text-white bg-gray-700">
                    <div>
                      <h3 className="text-3xl font-semibold text-center sm:-mx-6">
                        Free Woodwick Candle
                      </h3>
                      <div className="flex items-center justify-center mt-4">
                        <span className="flex items-start px-3 text-6xl tracking-tight sm:text-6xl">
                          <span className="mt-2 mr-2 text-4xl font-medium">
                            $
                          </span>
                          <span className="font-extrabold">44.95</span>
                        </span>
                        <span className="text-2xl font-medium">RRP</span>
                      </div>
                    </div>
                  </div>
                  <div className="px-6 pt-10 pb-8 bg-gray-50 sm:px-10 sm:py-10">
                    <div className="flex items-start">
                      <p className="text-base font-medium text-gray-500">
                        When you purchase a $150 Gift Voucher.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="max-w-md mx-auto mt-10 lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                <div className="flex flex-col h-full overflow-hidden shadow-lg">
                  <div className="flex flex-col flex-1">
                    <div className="px-6 py-10 text-white bg-pink">
                      <div>
                        <h3 className="text-2xl font-medium text-center">
                          Free Crystal Candle
                        </h3>
                        <div className="flex items-center justify-center mt-4">
                          <span className="flex items-start px-3 text-6xl tracking-tight">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              $
                            </span>
                            <span className="font-extrabold">52.95</span>
                          </span>
                          <span className="text-xl font-medium">RRP</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col justify-between flex-1 p-6 border-t-2 border-gray-100 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                      <div className="flex items-start">
                        <p className="text-base font-medium text-gray-500">
                          When you purchase a $200 Gift Voucher.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-12 mx-auto prose-sm">
              <p>
                <small>
                  CONDITIONS APPLY: Offer shown is an exclusive promotion and is
                  not valid with any other offer, discount or voucher. Offer
                  cannot cross-redeemed. Not redeemable for cash or products. No
                  refunds. Non-transferable. No substitutions. Offer available
                  while stock lasts. Offer available for purchase until 9 May
                  2021 or until sold out. Treatments are redeemable for a
                  limited time. a Prices are subject to change without notice.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}